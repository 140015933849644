import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
// @ts-expect-error
import logo from "../assets/logo_footer.svg";
// @ts-expect-error
import logo_red from "../assets/logo_footer_red.svg";

function Footer() {
	return (
		<footer className="Footer">
			<div className="Footer-Content">
				<div className="left">
					<div className="logo">
						<img
							src={logo}
							width={64}
							height={86}
							alt="Logo von Firmenradl"
							className={"white"}
						/>
						<img
							src={logo_red}
							width={64}
							height={86}
							alt="Logo von Firmenradl"
							className={"red"}
						/>
					</div>
					<div className="text">
						<h4>Hast du noch Fragen?</h4>
						<p>
							Kontaktiere uns unter <br />
							Mail:{" "}
							<a href="mailto:office@firmenradl.at">office@firmenradl.at</a>
							<br />
							Hotline: <a href="tel:0800201909">0800 / 20 19 09</a>
							<br />
							Mo.-Fr.: 9:00 bis 16:00 Uhr (zum Ortstarif)
						</p>
					</div>
				</div>
				<div className="right">
					<div className="row">
						<div className="col">
							<h4>Infos</h4>
							<ul>
								<li>
									<Link to="/ablauf">Ablauf</Link>
								</li>
								<li>
									<Link to="/haendlermap">Radl-Händler Karte</Link>
								</li>
								<li>
									<Link to="/faq">FAQ - Häufige Fragen</Link>
								</li>
								<li>
									<Link
										to="/marken"
										onClick={() => {
											window.scrollTo(0, 0);
										}}
									>
										Marken
									</Link>
								</li>
								<li>
									<a
										href="https://karriere.intersport.at/de/jobs#searchText=Firmenradl&group=&location=&state=&position=&type=&rent=false"
										target="_blank"
										rel="noreferrer"
									>
										Karriere
									</a>
								</li>
								<li>
									<a
										href="https://www.willhaben.at/iad/shop/intersport-firmenradl"
										target="_blank"
										rel="noreferrer"
									>
										Willhaben
									</a>
								</li>
							</ul>
						</div>
						<div className="col">
							<h4>Social Media</h4>
							<ul>
								<li>
									<a
										href="https://facebook.com/FIRMENRADLat-335283867875755/"
										target="_blank"
										rel="noreferrer"
									>
										Facebook
									</a>
								</li>
								<li>
									<a
										href="https://www.youtube.com/channel/UCk_ntJawblfRgg7uQQ5ouJQ"
										target="_blank"
										rel="noreferrer"
									>
										YouTube
									</a>
								</li>
								<li>
									<a
										href="https://www.instagram.com/firmenradl.at/"
										target="_blank"
										rel="noreferrer"
									>
										Instagram
									</a>
								</li>
								<li>
									<a
										href="https://www.pinterest.de/firmenradl/_created/"
										target="_blank"
										rel="noreferrer"
									>
										Pinterest
									</a>
								</li>
								<li>
									<a
										href="https://www.linkedin.com/company/71267100/admin"
										target="_blank"
										rel="noreferrer"
									>
										LinkedIn
									</a>
								</li>
							</ul>
						</div>
						<div className="col">
							<h4>Rechtliches</h4>
							<ul>
								<li>
									<Link to="/impressum">Impressum</Link>
								</li>
								<li>
									<Link to="/datenschutzerklarung">Datenschutzerklärung</Link>
								</li>
								<li>
									<Link to="/cookie-policy">Cookie Policy</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
